import React from 'react';
import { IconButton } from '@material-ui/core';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { toggleNotificationSound } from '../../../redux/actions/notification.actions';

const useStyles = makeStyles({
  volumeButton: {
    height: 'fit-content',
    padding: 0,
    '& svg': {
      fontSize: '14px',
    },
  },
});

function CloseButton() {
  const classes = useStyles();
  const sound = useSelector((state) => state.notification.sound);

  const dispatch = useDispatch();
  const onSoundBtnClick = () => dispatch(toggleNotificationSound);

  return (
    <IconButton
      aria-label="notification sound"
      onClick={onSoundBtnClick}
      className={classes.volumeButton}
    >
      {sound ? <VolumeUpIcon /> : <VolumeOffIcon />}
    </IconButton>
  );
}

export default CloseButton;
