import { Box, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { QuickLink } from '.';
import { changeTime } from '../../redux/actions/time.actions';
import { QUICK } from '../../services/constants';

const quickpicks = [
  'today',
  'thisweek',
  'thismonth',
  'thisyear',
  'thedaysofar',
  'weektodate',
  'monthtodate',
  'yeartodate',
  'yesterday',
  'daybeforeyesterday',
  'thisdaylastweek',
  'previousweek',
  'previousmonth',
  'previousyear',
  'last15minutes',
  'last30minutes',
  'last1hour',
  'last4hours',
  'last12hours',
  'last24hours',
  'last7days',
  'last30days',
  'last60days',
  'last90days',
  'last6months',
  'last1year',
  'last2years',
  'last5years',
];

class QuickPanel extends Component {
  createDates = (timerange) => {
    const { airngbarCallback, changeTimeProp } = this.props;
    const pickerMenuTitle = {
      type: QUICK,
      id: `picker_quick_${timerange}`,
      timerange,
    };
    changeTimeProp(pickerMenuTitle);
    airngbarCallback();
  };

  render() {
    return (
      <Box textAlign="left">
        <Grid container spacing={4}>
          <Grid item>
            {quickpicks.slice(0, 8).map((quickpick) => (
              <QuickLink
                key={quickpick}
                timerange={quickpick}
                onClick={() => this.createDates(quickpick)}
              />
            ))}
          </Grid>
          <Grid item>
            {quickpicks.slice(8, 14).map((quickpick) => (
              <QuickLink
                key={quickpick}
                timerange={quickpick}
                onClick={() => this.createDates(quickpick)}
              />
            ))}
          </Grid>
          <Grid item>
            {quickpicks.slice(14, 21).map((quickpick) => (
              <QuickLink
                key={quickpick}
                timerange={quickpick}
                onClick={() => this.createDates(quickpick)}
              />
            ))}
          </Grid>
          <Grid item>
            {quickpicks.slice(21).map((quickpick) => (
              <QuickLink
                key={quickpick}
                timerange={quickpick}
                onClick={() => this.createDates(quickpick)}
              />
            ))}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

QuickPanel.propTypes = {
  airngbarCallback: PropTypes.func.isRequired,
  changeTimeProp: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  changeTimeProp: (title, from, to) => dispatch(changeTime(title, from, to)),
});

export default connect(null, mapDispatchToProps)(QuickPanel);
