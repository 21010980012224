import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Menu from './Menu';

const styles = () => ({
  list: {
    width: 250,
    '& > ul': {
      padding: 0,
    },
  },
});

const mapStateToProps = (state) => ({
  categories: state.category.categories,
  loading:
    state.category.loading ||
    state.fas.keycloak.loading ||
    state.configuration.loading,
});

export default connect(mapStateToProps)(withStyles(styles)(Menu));
