import React from 'react';

import image from './assets/work_in_progress.jpg';

function WorkInProgress() {
  return (
    <div>
      <img src={image} alt="Work in progress" height="100%" width="100%" />
    </div>
  );
}

export default WorkInProgress;
