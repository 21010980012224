import { connect } from 'react-redux';
import AppFrame from './AppFrame';

const mapStateToProps = (state) => ({
  selectedApplication: state.category.currentApp,
  to: state.time.to,
  from: state.time.from,
  refreshStatus: state.time.refreshStatus,
  refreshInterval: state.time.refreshInterval,
});

export default connect(mapStateToProps)(AppFrame);
