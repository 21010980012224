import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setCurrentApp } from '../../../redux/actions/category.actions';
import ItemMenu from './ItemMenu';

const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  category: {
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
  },
  link: {
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentApp: (application) => dispatch(setCurrentApp(application)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(ItemMenu));
