/* eslint-disable react/prop-types */
import React from 'react';

import Welcome from '../Welcome';

function Iframe(props, ref) {
  const { name, url, classes } = props;
  return url ? (
    <iframe
      ref={ref}
      className={classes.appFrame}
      key={url}
      sandbox="allow-downloads allow-same-origin allow-scripts allow-popups allow-forms"
      id="rng-appframe"
      title={name || 'Application name is not defined.'}
      src={url}
    />
  ) : (
    <Welcome />
  );
}

export default Iframe;
