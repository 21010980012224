import { put } from 'fas-http-helper';

export const CHANGE_THEME = '@config/CHANGE_THEME';
export const CHANGE_THEME_SUCCESS = '@config/CHANGE_THEME_SUCCESS';
export const CHANGE_THEME_ERROR = '@config/CHANGE_THEME_ERROR';

export const CHANGE_LANGUAGE = '@config/CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_SUCCESS = '@config/CHANGE_LANGUAGE_SUCCESS';
export const CHANGE_LANGUAGE_ERROR = '@config/CHANGE_LANGUAGE_ERROR';

export const changeLanguage = (id) => async (dispatch, getState) => {
  dispatch({ type: CHANGE_LANGUAGE });
  const token = getState().fas.keycloak.accessToken;
  const userID = getState().fas.keycloak.parsedToken.user_id;
  put(
    'user/language',
    userID,
    { language: id },
    {
      Authorization: `Bearer ${token}`,
    }
  )
    .then(() => {
      dispatch({ type: CHANGE_LANGUAGE_SUCCESS, payload: id });
    })
    .catch((err) => {
      dispatch({ type: CHANGE_LANGUAGE_ERROR, payload: err });
    });
};

export const changeTheme = (id) => async (dispatch, getState) => {
  dispatch({ type: CHANGE_THEME });
  const token = getState().fas.keycloak.accessToken;
  const userID = getState().fas.keycloak.parsedToken.user_id;
  put(
    'user/theme',
    userID,
    { theme: id },
    {
      Authorization: `Bearer ${token}`,
    }
  )
    .then(() => {
      dispatch({ type: CHANGE_THEME_SUCCESS, payload: id });
    })
    .catch((err) => {
      dispatch({ type: CHANGE_THEME_ERROR, payload: err });
    });
};
