/* eslint-disable react/forbid-prop-types */
import { Box, Button, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { changeTime } from '../../redux/actions/time.actions';
import { ABSOLUTE } from '../../services/constants';
import { AbsolutePicker } from '.';

class AbsolutePanel extends Component {
  constructor(props) {
    const { dateFrom, dateTo } = props;
    super(props);
    this.state = {
      dateFrom,
      dateTo,
    };
  }

  onChangeDateFrom = (date) => {
    this.setState(() => ({ dateFrom: date }));
  };

  onChangeDateTo = (date) => {
    this.setState(() => ({ dateTo: date }));
  };

  onSubmit = () => {
    const { airngbarCallback, changeTimeProp } = this.props;
    const { dateFrom, dateTo } = this.state;
    changeTimeProp({ type: ABSOLUTE }, dateFrom, dateTo);
    airngbarCallback();
  };

  isPickValid = () => {
    const { dateFrom, dateTo } = this.state;
    return (dateTo.now ? dayjs() : dateTo).isAfter(dateFrom);
  };

  render() {
    const { intl } = this.props;
    const { dateFrom, dateTo } = this.state;
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AbsolutePicker
            date={dateFrom}
            onChange={this.onChangeDateFrom}
            maxDate={dateTo.now ? dayjs() : dateTo}
            label={intl.formatMessage({ id: 'picker_from' })}
          />
        </Grid>
        <Grid item xs={6}>
          <AbsolutePicker
            date={dateTo.now ? dayjs() : dateTo}
            onChange={this.onChangeDateTo}
            minDate={dateFrom}
            label={intl.formatMessage({ id: 'picker_to' })}
          />
          <Grid container spacing={0}>
            <Grid item xs={8} />
            <Grid item xs={4}>
              {this.isPickValid() && (
                <Box marginTop={3}>
                  <Button variant="contained" onClick={this.onSubmit}>
                    <FormattedMessage id="picker_ok" />
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

AbsolutePanel.propTypes = {
  airngbarCallback: PropTypes.func.isRequired,
  changeTimeProp: PropTypes.func.isRequired,
  dateTo: PropTypes.object.isRequired,
  dateFrom: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  changeTimeProp: (title, from, to) => dispatch(changeTime(title, from, to)),
});

export default connect(null, mapDispatchToProps)(injectIntl(AbsolutePanel));
